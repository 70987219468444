/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2"
  }, _provideComponents(), props.components), {HrefLangManager, ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/resources/hearing-aids/itchy-ears-and-new-hearing-aids/'], ['en', 'https://www.hear.com/resources/hearing-aids/itchy-ears-and-new-hearing-aids/'], ['en-US', 'https://www.hear.com/resources/hearing-aids/itchy-ears-and-new-hearing-aids/'], ['en-CA', 'https://ca.hear.com/resources/hearing-aid-success/itchy-ears-and-new-hearing-aids/']]
  }), "\n", React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "itchy-ears-and-new-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#itchy-ears-and-new-hearing-aids",
    "aria-label": "itchy ears and new hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Itchy Ears and New Hearing Aids?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you have new hearing aids and your ears itch, there’s no need to worry. In fact, it’s pretty normal among first-time users. The skin in the ear canal is very delicate and sensitive. So, when the domes of your hearing aids touch your ear canal, it can create a tickling sensation. Many people say it almost feels like a feather or hair lightly touching the skin. Like many new sensations, it’s only temporary. As your brain and body get used to it, it will pass with time. That’s why it’s important to keep wearing your hearing aids based on the recommended wearing schedule. And before you know it, the sensation will be gone."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you notice your skin is dry, irritated, or the itching doesn’t decrease, here’s what we recommend:"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "try-a-moisturizer-or-lubricant",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#try-a-moisturizer-or-lubricant",
    "aria-label": "try a moisturizer or lubricant permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Try a moisturizer or lubricant"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you want to be proactive or need some immediate relief, try applying a few drops of lubricant into your ear canal. Just make sure to apply the lubricant after you are done wearing your hearing aids for the day. And remember to wipe your ears without entering the ear canal before you put your hearing aids back on.  Doing so will prevent any potential damage to your hearing aids."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "check-the-fit-of-your-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#check-the-fit-of-your-hearing-aids",
    "aria-label": "check the fit of your hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Check the fit of your hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Sometimes, if the fit of a hearing aid is not right, it can cause an itching sensation. First, check to make sure you’re putting your hearing aids on correctly. The hearing aid with a red marking is for the right ear. The one with a blue marking is for the left ear. There should be no gaps between the surface of the hearing aid and your skin."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Also, make sure you’re wearing your hearing aids every day for at least a few hours to see if the itching goes away on its own. If you don’t notice any improvements, we can connect with your hearing care professional to address the issue."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "other-common-causes-of-itchy-ears",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#other-common-causes-of-itchy-ears",
    "aria-label": "other common causes of itchy ears permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Other common causes of itchy ears"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In some cases, people may experience itchy ears for reasons unrelated to their hearing aids. Common causes include dry skin, earwax blockage, ear infections, food allergies, seasonal allergies, or swimmers ear. If you suspect any of these factors are contributing to itchy ears, please let us know."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "were-here-to-help",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#were-here-to-help",
    "aria-label": "were here to help permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "We’re here to help"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At hear.com, our goal is to guide and support you on your journey to better hearing. If you have any questions, concerns, or want to discuss itchy ears, please don’t hesitate to give us a call at (866) 585-8861 or fill the form ."), "\n", React.createElement(ButtonCta, {
    copy: "Get Support",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
